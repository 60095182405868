import React from "react";
import {
  LinkItem,
  NavContainer,
  NavLogo
} from "./NavBar.css";
const NavBar = () => {
  return (
    <NavContainer>
      <LinkItem to={"/"}>
        <NavLogo src={process.env.PUBLIC_URL + "/UTTLogo.png"} />
      </LinkItem>
      {/* <MobileNavLinks onClick={menuFunc}>
        <Menu />
      </MobileNavLinks>
      <NavLinks>
        <NavLinkItem to={"/"} active={location.pathname === "/"}>
          Home
        </NavLinkItem>
        <NavLinkItem to={"/about"} active={location.pathname === "/about"}>
          About Us
        </NavLinkItem>
        <NavLinkItem to={"/awards"} active={location.pathname === "/awards"}>
          Awards
        </NavLinkItem>
        <NavLinkItem to={"/people"} active={location.pathname === "/people"}>
          People
        </NavLinkItem>
        <NavLinkItem to={"/careers"} active={location.pathname === "/careers"}>
          Careers
        </NavLinkItem>
        <NavLinkItem to={"/contact"} active={location.pathname === "/contact"}>
          Contact Us
        </NavLinkItem>
      </NavLinks>
      <MenuBar show={showMenu}>
        <Close onClick={hidemenuFunc} />
        <NavLinksContainer>
          <NavLinkItem
            to={"/"}
            onClick={hidemenuFunc}
            active={location.pathname === "/"}
          >
            Home
          </NavLinkItem>
          <NavLinkItem
            to={"/about"}
            onClick={hidemenuFunc}
            active={location.pathname === "/about"}
          >
            About Us
          </NavLinkItem>
          <NavLinkItem
            to={"/awards"}
            onClick={hidemenuFunc}
            active={location.pathname === "/awards"}
          >
            Awards
          </NavLinkItem>
          <NavLinkItem
            to={"/people"}
            onClick={hidemenuFunc}
            active={location.pathname === "/people"}
          >
            People
          </NavLinkItem>
          <NavLinkItem
            to={"/careers"}
            onClick={hidemenuFunc}
            active={location.pathname === "/careers"}
          >
            Careers
          </NavLinkItem>
          <NavLinkItem
            to={"/contact"}
            onClick={hidemenuFunc}
            active={location.pathname === "/contact"}
          >
            Contact Us
          </NavLinkItem>
        </NavLinksContainer>
      </MenuBar> */}
    </NavContainer>
  );
};

export default NavBar;
